import {
  HealthMonitorCodes,
  SessionState,
  SubjectDemographic,
  isIos,
  isMobile,
  isTablet,
} from "@binah/web-sdk";
import { addDoc, collection } from "firebase/firestore";
import { useUser } from "hooks/useAuth";
import useLicense from "hooks/useLicense";
import useOnboardingFinished from "hooks/useOnboardingFinished";
import { createMeasurementData } from "models/measurement";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useMediaPredicate } from "react-media-hook";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { BinahError } from "util/errors";
import { db } from "util/firebase";
import Mask from "../assets/mask.svg";
import {
  useError,
  useMonitor,
  usePageVisibility,
  usePrevious,
  useWarning,
} from "../hooks";
import media from "../style/media";
import { mirror } from "../style/mirror";
import { VideoReadyState } from "../types";
import InfoBar from "./InfoBar";
import Loader from "./Loader";
import StartButton from "./StartButton";
import Stats from "./Stats";
import Timer from "./Timer";
import { ErrorAlert, InfoAlert, WarningAlert } from "./alert";
import { Flex } from "./shared/Flex";
import ControlBar from "./ControlBar";
import ChangeTabAlert from "./ChangeTabAlert";

const MonitorWrapper = styled(Flex)<{ isSettingsOpen: boolean }>`
  flex-direction: column;
  width: 100%;
  justify-content: start;
  align-items: center;
  flex: 1;
  z-index: ${({ isSettingsOpen }) => isSettingsOpen && "-1"};
  ${media.tablet`
    width: fit-content;
    justify-content: center;
  `}
`;

const MeasurementContentWrapper = styled(Flex)<{ isMobile: boolean }>`
  width: auto;
  height: ${({ isMobile }) => isMobile && "100%"};
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  ${media.mobile`
    margin: 40px 0 60px 0;
  `}
`;

const VideoAndStatsWrapper = styled(Flex)<{ isMobile: boolean }>`
  position: relative;
  justify-content: center;
  width: 100%;
  height: ${({ isMobile }) => isMobile && "100%"};
  ${media.tablet`
    width: 812px;
    height: 609px;
  `} ${media.wide`
    width: 1016px;
    height: 762px;
  `};
`;

const VideoWrapper = styled.div`
  width: 100%;
  height: 100%;
  z-index: -1;
`;

const Img = styled.img<{ isDesktop: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  object-fit: ${({ isDesktop }) => (isDesktop ? "contain" : "cover")};
`;

const Video = styled.video<{ isMobile: boolean }>`
  width: 100%;
  height: 100%;
  object-fit: ${() => (isIos() ? "unset" : isMobile() ? "cover" : "contain")};
  ${mirror}
`;

// const ButtonWrapper = styled(Flex)`
//   flex: 2;
//   z-index: 3;
//   width: 100%;
//   flex-direction: column;
//   justify-content: start;
//   align-items: center;
//   margin-top: -30px;
//   ${media.mobile`
//     margin-top: 50px;
//   `}
//   ${media.tablet`
//   padding: 0;
//   height: auto;
//   width: auto;
//   position: absolute;
//   right: 0;
//   bottom: 42%;
//   margin-right: 60px;
// `}
// `;

const ButtomTimerWrapper = styled(Flex)`
  display: none;
  ${media.tablet`
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    height: 30px;
    display: flex;
  `}
`;

const InfoBarWrapper = styled.div`
  height: 25px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  ${media.mobile`
    flex: 0.45;
  `}
`;

enum UPLOAD_STATUS {
  IDLE,
  UPLOADING,
  SUCCESS,
  ERROR,
}

type Props = {
  showMonitor: boolean;
  cameraId: string;
  onLicenseStatus: (valid: boolean) => void;
  subjectDemographic: SubjectDemographic;
};

const BinahMonitor = ({
  showMonitor,
  cameraId,
  onLicenseStatus,
  subjectDemographic,
}: Props) => {
  if (!showMonitor) {
    return null;
  }

  const video = useRef<HTMLVideoElement>(null);
  const [isMeasurementEnabled, setIsMeasurementEnabled] =
    useState<boolean>(false);
  const [startMeasuring, setStartMeasuring] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingTimeoutPromise, setLoadingTimeoutPromise] = useState<number>();
  const isPageVisible = usePageVisibility();
  const isMediaTablet = useMediaPredicate("(min-width: 1000px)");
  // const [processingTime] = useMeasurementDuration();
  // const [licenseKey] = useLicenseKey();

  // Load license key from the server
  // const {
  //   token: licenseKey,
  //   error: licenseKeyError,
  //   isLoading: licenseKeyLoading,
  // } = useToken();

  const license = useLicense();
  // const license: LicenseType = {
  //   licenseKey: "fda",
  //   processingTime: 60,
  // };
  const processingTime = license?.processingTime;

  const {
    session,
    sessionState,
    vitalSigns,
    offlineMeasurements,
    error,
    warning,
    info,
    finalResults,
  } = useMonitor({
    video: video,
    cameraId: cameraId,
    processingTime: processingTime,
    licenseKey: license?.licenseKey,
    startMeasuring: startMeasuring,
    productId: null,
    subjectDemographic: subjectDemographic,
  });
  const prevSessionState = usePrevious(sessionState);
  const errorMessage: BinahError = useError(error);
  const warningMessage = useWarning(warning);

  const isMeasuring = useCallback(
    () => sessionState === SessionState.MEASURING,
    [sessionState]
  );

  const isVideoReady = useCallback(
    () => video.current?.readyState === VideoReadyState.HAVE_ENOUGH_DATA,
    []
  );

  const handleButtonClick = useCallback(() => {
    setIsLoading(true);
    if (sessionState === SessionState.ACTIVE) {
      setStartMeasuring(true);
      setLoadingTimeoutPromise(
        window.setTimeout(() => setIsLoading(true), processingTime * 1000)
      );
    } else if (isMeasuring()) {
      clearTimeout(loadingTimeoutPromise);
      setStartMeasuring(false);
    }
  }, [sessionState, setIsLoading, processingTime]);

  useEffect(() => {
    if (isMeasuring()) {
      setIsLoading(false);
      if (errorMessage) {
        setIsMeasurementEnabled(false);
      } else {
        setIsMeasurementEnabled(true);
      }
      !isPageVisible && setStartMeasuring(false);
    } else if (
      (sessionState === SessionState.ACTIVE ||
        sessionState === SessionState.TERMINATED) &&
      errorMessage
    ) {
      setIsMeasurementEnabled(false);
    }
    if (
      sessionState === SessionState.ACTIVE &&
      prevSessionState !== sessionState
    ) {
      setStartMeasuring(false);
      setIsLoading(false);
    }
  }, [errorMessage, sessionState, isPageVisible]);

  useEffect(() => {
    onLicenseStatus(!(error?.code in HealthMonitorCodes));
  }, [error]);

  const user = useUser();
  const [uploadStatus, setUploadStatus] = useState<UPLOAD_STATUS>(
    UPLOAD_STATUS.IDLE
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (finalResults && uploadStatus === UPLOAD_STATUS.IDLE) {
      if (Object.keys(finalResults.results).length === 0) {
        return;
      }

      async function upload() {
        setUploadStatus(UPLOAD_STATUS.UPLOADING);
        const d = new Date();
        const data = createMeasurementData(d, finalResults!.results);

        const c = collection(db, "users", user.uid, "measurements");
        const docRef = await addDoc(c, data);
        setUploadStatus(UPLOAD_STATUS.SUCCESS);

        console.log("Document written with ID: ", docRef.id);
        navigate(`/history/${docRef.id}`);
      }

      upload();
    }
  }, [finalResults, uploadStatus, user.uid]);

  const mobile = useMemo(() => isMobile(), []);
  const desktop = useMemo(() => !isTablet() && !isMobile(), []);

  const onboardingFinished = useOnboardingFinished();

  if (onboardingFinished === false) {
    return <Navigate to="/onboarding" replace={true} />;
  }

  if (sessionState === SessionState.TERMINATED) {
    return <div>Disposed</div>;
  }

  return (
    <>
      <MonitorWrapper isSettingsOpen={false}>
        <MeasurementContentWrapper isMobile={mobile}>
          <ChangeTabAlert />
          <ErrorAlert error={errorMessage} />

          {/* 
          <InfoBarWrapper>
            <InfoBar
              showTimer={isMeasurementEnabled && !isMediaTablet}
              isMeasuring={isMeasuring()}
              durationSeconds={processingTime}
              offlineMeasurements={offlineMeasurements}
            />
          </InfoBarWrapper> */}
          <ControlBar
            showTimer={isMeasurementEnabled && !isMediaTablet}
            isMeasuring={isMeasuring()}
            durationSeconds={processingTime}
            isLoading={isLoading}
            onClick={handleButtonClick}
          />

          <VideoAndStatsWrapper isMobile={mobile}>
            <VideoWrapper>
              <Img src={Mask} isDesktop={desktop} />
              <Video
                ref={video}
                id="video"
                muted={true}
                playsInline={true}
                isMobile={isMobile()}
              />
            </VideoWrapper>
            {(isMeasuring()
              ? !errorMessage && !warningMessage
              : !errorMessage) &&
              isMeasurementEnabled && <Stats vitalSigns={vitalSigns} />}
            {isMeasuring() && <WarningAlert message={warningMessage} />}
            {isMeasuring() && <InfoAlert message={info.message} />}
            {!isVideoReady() && license && <Loader />}
          </VideoAndStatsWrapper>
          <ButtomTimerWrapper>
            {isMeasurementEnabled && (
              <Timer started={isMeasuring()} durationSeconds={processingTime} />
            )}
          </ButtomTimerWrapper>
          {/* <StartButton
            isLoading={isLoading}
            isMeasuring={isMeasuring()}
            onClick={handleButtonClick}
          /> */}
        </MeasurementContentWrapper>
      </MonitorWrapper>
    </>
  );
};

export default BinahMonitor;
